import PropTypes from "prop-types";

/**
 *
 * Model used to represent a `User`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model (but this is a lie hehehehe)
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyUser = {
  id: 0,
  name: "",
  email: "",
  role: "",
  permissions: [],
  emailVerifiedAt: null,
  hasTwoFactor: false,
};

/**
 *
 * PROP TYPES
 *
 */
const adminUserPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  hasTwoFactor: PropTypes.string.isRequired,
});

/**
 * FORMATTERS
 */
const convertUserFromServerToStore = (user) => {
  const data = {};

  Object.keys(user).forEach((key) => {
    if (key in emptyUser) {
      data[key] = user[key];
    }
  });

  Object.keys(emptyUser).forEach((key) => {
    if (!(key in user)) {
      data[key] = emptyUser[key];
    }
  });

  return data;
};

export { emptyUser, adminUserPropType, convertUserFromServerToStore };
