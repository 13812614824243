import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { reduxForm, Field, SubmissionError, reset } from "redux-form";
import { ConnectedRouter, TextInput } from "@jauntin/react-ui";
import * as qs from "query-string";
import { twoFactorFormName } from "../../../constants";
import { required } from "../../../Helpers/validators";
import AuthService from "@basicare/common/src/Helpers/AuthService";
import API from "../../../Services/API";
import { getUrl, LOGIN_PAGE, MEMBERS_PAGE } from "../../../Helpers/URLParser";
import { FormPropTypes } from "@basicare/common/src/Constants/ReduxFormPropTypes";
import { updateLoginStatus } from "../../../Redux/Actions/actions";
import Logo from "@basicare/common/src/Assets/Images/basicare.900.transparent.png";
import { Button } from "@jauntin/react-ui";
import { normalizers } from "@jauntin/utilities";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { adminUserPropType } from "../../../Helpers/UserModel";
import { pluralize } from "@basicare/common/src/Helpers/StringHelper";

const normalize6Digits = normalizers.normalizeDigits(6);

const CountdownTimer = ({ resendVerification }) => {
  const [timeLeft, setTimeLeft] = useState(30);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setShowButton(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, setShowButton, setTimeLeft]);

  return (
    <div>
      Did not receive an email?{" "}
      {showButton ? (
        <Button
          className="btn-link"
          type="button"
          text="Resend verification code"
          onClick={() => {
            resendVerification(setError);
            setShowButton(false);
            setTimeLeft(30);
          }}
        />
      ) : (
        <span>
          Resend verification code in {timeLeft} {pluralize("second", timeLeft)}
        </span>
      )}
      {error && (
        <div className="form-error my-1 ml-2 text-left">
          Something went wrong, please try again
        </div>
      )}
    </div>
  );
};

CountdownTimer.propTypes = {
  resendVerification: PropTypes.func.isRequired,
};

let TwoFactorForm = ({
  currentUser,
  valid,
  handleSubmit,
  resendVerification,
  submitting,
  error,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (!currentUser) {
      return history.push(getUrl(LOGIN_PAGE));
    }
  }, [history, currentUser]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="container text-center pt-2 mt-2">
        <img
          src={Logo}
          alt="Logo"
          className="img-fluid my-4 pt-5 col-md-8 col-lg-6"
        />
        <div className="col-sm-6 col-lg-4 mx-auto pt-5">
          <div>
            <Field
              label="Verification code"
              component={TextInput}
              validate={[required]}
              normalize={normalize6Digits}
              name="verificationCode"
              placeholder="XXXXXX"
              inputMode="numeric"
            />
          </div>
          {error && (
            <div className="form-error my-1 ml-2 text-left">{error}</div>
          )}
          <div>
            <Button
              disabled={!valid || submitting || !currentUser}
              text="Verify"
              type="submit"
              className="btn-primary w-100 my-3 py-2"
            />
            <CountdownTimer resendVerification={resendVerification} />
          </div>
        </div>
      </div>
    </form>
  );
};

TwoFactorForm = reduxForm({
  form: twoFactorFormName,
  shouldError: ({ initialRender, nextProps }) => {
    if (
      !initialRender &&
      nextProps.error &&
      !nextProps?.values?.verificationCode
    ) {
      return false;
    }
    return true;
  },
})(TwoFactorForm);

TwoFactorForm.propTypes = {
  ...FormPropTypes,
  currentUser: adminUserPropType,
  setShowLockout: PropTypes.func,
  resendVerification: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(async (_, getState) => {
      const state = getState();
      try {
        await new AuthService(new API()).verifyTwoFactor({
          ...values,
          credentials: {
            email: state.app.currentUser.email,
          },
        });
        dispatch(updateLoginStatus(true));
        const { returnUrl } = qs.parse(window.location.search);
        return dispatch(
          ConnectedRouter.push(returnUrl || getUrl(MEMBERS_PAGE))
        );
      } catch (e) {
        let message =
          "The verification code is invalid. Please request a new code and try again.";
        dispatch(updateLoginStatus(false));
        dispatch(reset(twoFactorFormName));
        throw new SubmissionError({
          _error: message,
        });
      }
    }),
  resendVerification: (setError) =>
    dispatch(async (_, getState) => {
      const state = getState();
      try {
        await new AuthService(new API()).resendTwoFactor({
          credentials: {
            email: state.app.currentUser.email,
          },
        });
        setError(null);
      } catch (e) {
        setError(e);
      }
    }),
});

const mapStateToProps = (state) => ({
  currentUser: state.app.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorForm);
